import * as React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import { graphql } from "gatsby"

import Header from "../components/Header"
import Seo from './seo'
import Layout from '../layout/global'

import Mdx from '../components/Mdx'

import * as designSystem from '../components/DesignSystem'
import * as globalCls from './../layout/Global.module.css'
import * as cls from './AboutUs.module.css'

const pageTitle = "About Us | Captain Bob Constructions"
const pageDescription = 'We differentiate on service and mastery. Think Captain Bob, think reliable. You can count on us to provide a highly professional and personalised service.'

const AboutUsPage = ({ data }) => {
	const { allMdx } = data
	const { nodes } = allMdx

	let about
	let services
	let cta

	nodes.map((node) => {
		const { frontmatter, body } = node
		const { page, section } = frontmatter
		if (page === "aboutUs" && section === "about") {
			about = body
		} else if (page === "aboutUs" && section === "services") {
			services = body
		} else if (page === "aboutUs" && section === "cta") {
			cta = body
		}
		return null
	})

	const defaultShortcodes = designSystem.shortcodes
	const defaultShortcodesBtn = designSystem.shortcodesBtnLight

	return (
		<main>
			<Seo title={pageTitle} description={pageDescription} pageUrl='/about-us/' />
			<Header pageName={pageTitle} pageDescription={pageDescription} pageUrl='/about-us/' />
			<Layout>
				{
					// About section
					<section className={`${globalCls.section} ${globalCls.padding} ${globalCls.grey__background}`}>
						<div
							className={`${globalCls.section__wrapper}`}
						>
							<Mdx content={about} components={defaultShortcodes} />
						</div>
					</section>
				}

				<div
				>
					{
						// Services Section
						<section className={`${globalCls.section} ${globalCls.padding}`}>
							<div className={`${globalCls.section__wrapper}`}>
								<Mdx content={services} components={defaultShortcodes} />
							</div>
						</section>
					}
				</div>

				{
					// CTA section
					<section className={`${globalCls.section} ${cls.cta__height}`}>
						<StaticImage
							src="../images/about-cta-bg.jpg"
							alt="A black and white background of a kitchen situated in a large renovated home"
							placeholder="blurred"
							loading="eager"
							layout="constrained"
							objectFit="cover"
							objectPosition="center"
						/>
						<div className={`${cls.overlay} ${globalCls.padding}`}>
							<div className={`${globalCls.section__wrapper}`}>
								<Mdx content={cta} components={defaultShortcodesBtn} />
							</div>
						</div>
					</section>
				}
			</Layout>
		</main>
	)
}

export default AboutUsPage

export const pageQuery = graphql`
  	query {
		allMdx {
			nodes {
			  body
			  frontmatter {
				page
				section
			  }
			}
		  }
	}
`
