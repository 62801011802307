import * as React from "react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"

const Mdx = ({ components, content, animate }) => (
	animate ?
		<MDXProvider components={components}>
			<div
				data-sal="slide-up"
				data-sal-duration="1000" // changes duration of the animation (from 200 to 2000 ms)
				data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
			>
				<MDXRenderer>{content}</MDXRenderer>
			</div>
		</MDXProvider> :
		<MDXProvider components={components}>
			<MDXRenderer>{content}</MDXRenderer>
		</MDXProvider>
)

export default Mdx